/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        "./assets/src/js/**/*.js",
        "./templates/**/*.html.twig",
        "./src/Form/Type/**/*.php",
        "./src/Controller/**/*.php",
        "./vendor/acc-digital/**/*.html.twig",
    ],
    safelist: process.env.NODE_ENV === "development" ? [{ pattern: /.*/ }] : [],
    darkMode: "class",
    theme: {
        screens: {
            xs: "380px",
            sm: "480px",
            md: "768px",
            lg: "1024px",
            xl: "1280px",
            xxl: "1440px",
            xxxl: "1680px",
            max: "1920px",
        },
        container: {
            center: true,
            padding: {
                DEFAULT: "1rem",
                sm: "1.25rem",
                md: "2rem",
                lg: "5rem",
                xl: "7rem",
                xxl: "9rem",
                xxxl: "11.625rem",
            },
            screens: {
                xs: "100%",
                sm: "100%",
                md: "768px",
                lg: "1024px",
                xl: "1280px",
                xxl: "1440px",
                xxxl: "1680px",
                max: "1920px",
            },
        },
        colors: {
            transparent: "transparent",
            current: "currentColor",
            white: "#ffffff",
            'skeleton-placeholder': "#dddddd",
            gray: {
                400: "#707070",
                500: "#485156",
            },
            blue: {
                300: "#86C2EB",
                500: "#417AA7",
                600: "#437FAF",
                900: "#244F75",
            },
            magazine:{
                'dark-blue': '#244F75',
                'light-blue': '#86C2EB',
                'medium-blue': '#437FAF',
                'dark-gray': '#485156',
                'sand': '#D8AC7A',
                'sun-yellow': '#FFCC00',
                'orange': '#EF7C00',
                'red': '#C92909',
                'olive': '#39571B',
                'medium-green': '#4F8934',
                'may-green': '#94BA1D',
                'petrol': '#28A593',
            },
            yellow: {
                500: "#FFCC00",
            },
            cyan: {
                500: "#28A596",
            },
            orange: {
                500: "#EF7C00",
            },
            admin: {
                yellow: {
                    100: "#f6e58d",
                    500: "#f7b731",
                },
            },
        },
        fontFamily: {
            "open-sans": ["Open Sans", "Roboto", "ui-sans-serif", "system-ui"],
        },
        fontWeight: {
            light: 300,
            regular: 400,
            semibold: 600,
            bold: 700,
        },
        fontSize: {
            14: "0.875rem",
            15: "0.9375rem",
            16: "1rem",
            18: "1.125rem",
            20: "1.25rem",
            24: "1.5rem",
            28: "1.75rem",
            30: "1.875rem",
            36: "2.25rem",
            40: "2.5rem",
            44: "2.75rem",
            48: "3rem",
            50: "3.125rem",
            56: "3.5rem",
            60: "3.75rem",
            70: "4.375rem",
            80: "5rem",
        },
        lineHeight: {
            none: 1,
            17: "1.0625rem",
            20: "1.25rem",
            24: "1.5rem",
            28: "1.75rem",
            30: "1.875rem",
            34: "2.125rem",
            38: "2.375rem",
            44: "2.75rem",
            48: "3rem",
            52: "3.25rem",
            64: "4rem",
            88: "5.5rem",
            90: "5.625rem",
            98: "6.125rem",
        },
        extend: {
            aspectRatio: {
                "5/3": "5 / 3",
                "A4": "1 / 1.414",
                "3/5": "3 / 5",
                "5/4": "5 / 4",
                "5/6": "5 / 6",
                "6/5": "6 / 5",
            },
            keyframes: {
                wiggle: {
                    "0%, 100%": { transform: "rotate(-5deg)" },
                    "50%": { transform: "rotate(5deg)" },
                },
            },
            animation: {
                wiggle: "wiggle 0.2s ease-in-out",
            },
            gridTemplateRows: {
                "0fr": "0fr",
                "1fr": "1fr",
            },
            height:{
                fullscreen: "var(--screen-height-minus-header)",
            },
            minHeight:{
                fullscreen: "var(--screen-height-minus-header)",
            },
            maxHeight:{
                fullscreen: "var(--screen-height-minus-header)",
            },
        },
    },
    plugins: [],
};
